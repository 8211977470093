<template>
  <div id="Student">
    <NOUNTop v-show="!this.$store.state.webview" />
    <b-navbar toggleable="lg" type="light" variant="light">
      <!-- <b-navbar-brand href="#">NavBar</b-navbar-brand> -->

      <b-navbar-toggle target="nav-collapse" v-show="!this.$store.state.webview"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <li class="nav-item">
            <router-link to="/create" class="nav-link">
              <b-icon-sticky></b-icon-sticky>
              Open New Ticket
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/my-tickets" class="nav-link">
              <b-icon-stickies></b-icon-stickies>
              My Tickets
            </router-link>
          </li>
          <li class="nav-item">
            <!-- <router-link to="/faq" class="nav-link"> -->
            <a href="https://nou.edu.ng/faqs/" target="_blank" class="nav-link">
              <b-icon-question-circle></b-icon-question-circle>
              FAQs
            </a>
            <!-- </router-link> -->
          </li>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <li class="nav-item">
            <router-link to="/login" class="nav-link">
              <b-icon-box-arrow-in-right></b-icon-box-arrow-in-right>
              Login
            </router-link>
          </li>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <router-view />
  </div>
</template>

<script>
import NOUNTop from '@/components/NOUNTop.vue'
export default {
  name: 'StudentLayout',
  components: {
    NOUNTop
  },
  computed: {
    year() {
      let d = new Date()
      return d.getFullYear()
    }
  }
}
</script>
